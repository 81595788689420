<template>
  <div class="pt-4">
    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4">Search results for "{{ searchString }}"</h1>
    <v-card :loading="loading" class="pa-0 fz-14 mb-6">
      <div class="py-4 pb-1 px-4 font-weight-semi-bold fz-12 text-uppercase">People</div>
      <v-list class="py-0">
        <template v-for="(person, personIndex) in peopleArray">
          <v-divider
            v-if="personIndex"
            :key="personIndex + 'v-divider--person'"
          ></v-divider>
          <v-list-item :key="personIndex + 'v-list-item--person'" class="px-5">
            <v-list-item-avatar width="50px" height="50px" class="my-4">
              <v-img
                :alt="`${person.firstname} avatar`"
                :src="person.avatar"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-bold">{{person.firstName}} {{person.lastName}}</v-list-item-title>

              <v-list-item-subtitle>{{person.phone}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center flex-row align-center v-list-item__action--hover">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">phone</v-icon>
                  </v-btn>
                </template>
                <span>Make a call</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">videocam</v-icon>
                  </v-btn>
                </template>
                <span>Make a video call</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">person</v-icon>
                  </v-btn>
                </template>
                <span>User information</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider/>
      <v-btn
        v-if="search.people.length > 5"
        tile
        text
        link
        class="text-none fill-width"
        color="link"
        height="50px"
        @click="peopleExpand = !peopleExpand"
      >
        Show all
        <v-icon color="regular-text" :class="{ 'button__icon--active': peopleExpand }">expand_more</v-icon>
      </v-btn>
      <!-- <v-divider/>
      <div class="py-4 pb-1 px-4 font-weight-semi-bold fz-12 text-uppercase">History</div>
      <v-list class="py-0">
        <template v-for="(item, historyIndex) in historyArray">
          <v-divider
            v-if="historyIndex"
            :key="historyIndex + 'v-divider--history'"
          ></v-divider>
          <v-list-item :key="historyIndex + 'v-list-item--history'" class="px-5">
            <v-list-item-avatar width="50px" height="50px" class="my-4">
              <v-img
                :alt="`${item.name} avatar`"
                :src="item.avatar + '?random=' + historyIndex"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-bold" v-text="item.name"></v-list-item-title>

              <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center flex-row align-center v-list-item__action--hover">
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">phone</v-icon>
                  </v-btn>
                </template>
                <span>Make a call</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">videocam</v-icon>
                  </v-btn>
                </template>
                <span>Make a video call</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">person</v-icon>
                  </v-btn>
                </template>
                <span>User information</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider/>
      <v-btn tile text link class="text-none fill-width " color="link" height="50px" @click="historyExpand = !historyExpand">
        Show all
        <v-icon color="regular-text" :class="{ 'button__icon--active': historyExpand }">expand_more</v-icon>
      </v-btn>
      <v-divider/>
      <div class="py-4 pb-1 px-4 font-weight-semi-bold fz-12 text-uppercase">Voicemails</div>
      <v-list class="pa-0">
        <template v-for="(vm, vmIndex) in voicemailsArray">
          <v-divider
            v-if="vmIndex"
            :key="vmIndex + 'v-divider--voicemails'"
          ></v-divider>
          <track-item :key="vmIndex + 'track'" :value="vm" class="px-5"/>
        </template>
      </v-list>
      <v-divider/>
      <v-btn tile text link class="text-none fill-width " color="link" height="50px"
             @click="voicemailsExpand = !voicemailsExpand">
        Show all
        <v-icon color="regular-text" :class="{ 'button__icon--active': voicemailsExpand }">expand_more</v-icon>
      </v-btn>
      <v-divider/> -->

    </v-card>
  </div>
</template>

<script>
// import TrackItem from '@/components/TrackItem'
import { mapActions } from 'vuex'

export default {
  name: 'SearchResults',
  // components: { TrackItem },
  data: () => ({
    loading: false,
    peopleExpand: false,
    // historyExpand: false,
    // voicemailsExpand: false,
    searchString: '',
    search: {
      people: [
        /* {
          name: 'Rosa Ferguson',
          phone: '080-021-3014',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Iva Nash',
          phone: '195-654-4944',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Eric Waters',
          phone: '101-290-9975',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Jeffery Jimenez',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        } */
      ],
      history: [
        /* {
          name: 'Rosa Ferguson',
          phone: '080-021-3014',
          date: '08 Apr 2017',
          time: '01:52PM',
          text: 'Speaking of which, Peter really wants you to come in on Friday…',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Steve Nash',
          phone: '195-654-4944',
          date: '08 Apr 2017',
          time: '01:52PM',
          text: 'A wonderful serenity has taken possession of my entire soul, like…',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Steph Curry',
          phone: '101-290-9975',
          date: '08 Apr 2017',
          time: '01:52PM',
          text: 'Speaking of which, Peter really wants you to come in on Friday.',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Jessica Alba',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          text: 'Images span the screen in ribbons.',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Moses Malone',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          text: 'Healthy, robust, contracting, healthy, robust and contracting like…',
          avatar: 'https://picsum.photos/200'
        }
      ],
      voicemails: [
        {
          name: 'Rosa Ferguson',
          phone: '080-021-3014',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Iva Nash',
          phone: '195-654-4944',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Eric Waters',
          phone: '101-290-9975',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Jeffery Jimenez',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Design mailbox  x4567',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Jeffery Jimenez',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Design mailbox  x4567',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Jeffery Jimenez',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Support mailbox  x8721',
          file: '/CafeDelMar.mp3',
          duration: 43
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          source: 'Design mailbox  x4567',
          file: '/CafeDelMar.mp3',
          duration: 43
        } */
      ]
    }
  }),
  computed: {
    peopleArray () {
      return this.peopleExpand && this.search ? this.search.people : this.search.people.slice(0, 5)
    }
    /* historyArray () {
      return this.historyExpand && this.search ? this.search.history : this.search.history.slice(0, 5)
    },
    voicemailsArray () {
      return this.voicemailsExpand && this.search ? this.search.voicemails : this.search.voicemails.slice(0, 5)
    } */
  },
  created () {
    this.searchString = this.$route.params.search
    this.getAllMembers()
  },
  methods: {
    ...mapActions(['getMembers']),
    async getAllMembers () {
      try {
        this.loading = true
        const { data } = await this.getMembers({
          orderType: 'desc',
          orderBy: 'created',
          take: 999
        })
        const people = []
        data.members
          .filter(e =>
            e.firstName?.toLowerCase().includes(this.searchString.toLowerCase()) ||
            e.lastName?.toLowerCase().includes(this.searchString.toLowerCase()) ||
            e.userPhoneNumber?.toString().includes(this.searchString.toString())
          )
          .forEach(item => {
            const user = {
              uuid: item.uuid,
              firstName: item.firstName,
              lastName: item.lastName,
              phone: item.userPhoneNumber,
              avatar: 'https://picsum.photos/' + item.id
            }
            people.push(user)
          })
        this.search.people = people
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
